// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import ProductPage from './Pages/ProductPage';  // Create a ProductPage component
import PaymentPage from './Pages/PaymentPage';  // The existing payment form component
import SuccessPage from './SuccessPage';  // Create a SuccessPage component
import FailedPage from './FailedPage';  // Create a FailedPage component

const App = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<PaymentPage />} />
                <Route path="/pay" element={<PaymentPage />} />
                <Route path="/success" element={<SuccessPage />} />
                <Route path="/failed" element={<FailedPage />} />

            </Routes>
        </Router>
    );
};

export default App;
