import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './PaymentPage.css';
import { Toaster, toast } from 'react-hot-toast';
import { countryCodes } from '../countryCodes';
import { useNavigate } from 'react-router-dom';
import ReactPixel from 'react-facebook-pixel';
import { X } from 'lucide-react';

const logoUrl = 'https://kredallino.com/wp-content/uploads/2024/06/Krediffy-320-x-94-px-470-x-110-px-8.svg';
const API_URL = process.env.REACT_APP_API_URL || 'api';

const PaymentPage = () => {
  const [userDetails, setUserDetails] = useState({
    name: '',
    email: '',
    phone: '',
    tradingViewId: '',
    country: '',
    state: '',
    postalCode: '',
    address: '',
  });
  const [countryCode, setCountryCode] = useState(countryCodes[0].code);
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(plans[0]);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showVideoModal, setShowVideoModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await axios.get(`${API_URL}/algomaxx/plans?for=ultra-signal`);
        setPlans(response.data);
        setSelectedPlan(response.data[0]);
      } catch (error) {
        console.error('Error fetching plans:', error);
        toast.error('Failed to fetch plans.');
      }
    };

    fetchPlans();

    const options = {
      autoConfig: true,
      debug: false,
    };
    ReactPixel.init('827324705599112', null, options);
    ReactPixel.pageView();
  }, []);

  const handleChange = (e) => {
    setUserDetails({ ...userDetails, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!userDetails.email || !userDetails.phone || !userDetails.tradingViewId) {
      toast.error('Email, phone number, and TradingView ID are required.');
      return;
    }
    if (userDetails.phone.length !== 10) {
      toast.error('Invalid phone number (must be 10 digits)');
      return;
    }

    userDetails.phone = countryCode + userDetails.phone;

    if (!termsAccepted) {
      toast.error('You must accept the terms and conditions.');
      return;
    }

    try {
      toast.success('Redirecting to PayU for payment...');

      const transactionId = `txn_${Date.now()}`;
      const amount = selectedPlan.price;
      const callbackUrl = `${API_URL}/payu/payment-callback`;

      const response = await axios.post(`${API_URL}/payu/initiate-payment`, {
        transactionId,
        amount,
        redirectUrl: window.location.origin + '/success?transactionId=' + transactionId,
        callbackUrl,
        userDetails: {
          ...userDetails,
          plan: selectedPlan,
        },
      });

      const { action, params } = response.data;

      if (action) {
        const form = document.createElement('form');
        form.method = 'POST';
        form.action = action;

        Object.keys(params).forEach((key) => {
          const input = document.createElement('input');
          input.type = 'hidden';
          input.name = key;
          input.value = params[key];
          form.appendChild(input);
        });

        document.body.appendChild(form);
        form.submit();

        ReactPixel.track('Purchase', {
          value: selectedPlan.price,
          currency: 'INR',
        });
      } else {
        toast.error('Failed to initiate payment.');
      }
    } catch (err) {
      console.error('Payment initiation error:', err);
      toast.error('Error initiating payment.');
    }
  };

  const VideoModal = () => (
    <div className="video-modal-overlay">
      <div className="video-modal">
        <button className="close-modal" onClick={() => setShowVideoModal(false)}>
          <X size={24} />
        </button>
        <div className="video-container">
          <iframe
            width="100%"
            height="100%"
            src={selectedPlan?.youtubeLink || 'https://youtube.com/embeed/SKkuPwLR7kY'}
            title="How to get TradingView ID"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );

  return (
    <div className="payment-page">
      <div className="logo-container">
        <img src={logoUrl} alt="Brand Logo" className="brand-logo" />
      </div>

      <div className="payment-container">
        <Toaster />
        {paymentSuccess ? (
          <div className="success-message">
            <h2>Payment Successful!</h2>
            <p>
              Congratulations 🎉! Your payment for <strong>{selectedPlan?.name}</strong> has been successfully
              processed. Please check your email for further details.
            </p>
            <button className="dashboard-button" onClick={() => navigate('/dashboard')}>
              Go to Dashboard
            </button>
          </div>
        ) : (
          <div className="form-and-summary">
            <form className="user-form" onSubmit={handleSubmit}>
              <div className="customer-info">
                <h3>Customer Information</h3>
                <div className="form-group">
                  <input
                    type="text"
                    name="name"
                    className="form-input"
                    placeholder="Full Name"
                    value={userDetails.name}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group phone-email">
                  <div className="phone-input-group">
                    <select
                      className="form-input country-code-dropdown"
                      value={countryCode}
                      onChange={(e) => setCountryCode(e.target.value)}
                    >
                      {countryCodes.map((country) => (
                        <option key={country.code} value={country.code}>
                          {country.name} ({country.code})
                        </option>
                      ))}
                    </select>
                    <input
                      type="tel"
                      name="phone"
                      className="form-input"
                      placeholder="Phone"
                      value={userDetails.phone}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <input
                    type="email"
                    name="email"
                    className="form-input email"
                    placeholder="Email"
                    value={userDetails.email}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group1 country-state">
                  <input
                    type="text"
                    name="country"
                    className="form-input1"
                    placeholder="Country"
                    value={userDetails.country}
                    onChange={handleChange}
                    required
                  />
                  <input
                    type="text"
                    name="state"
                    className="form-input1"
                    placeholder="State"
                    value={userDetails.state}
                    onChange={handleChange}
                    required
                  />
                  <input
                    type="text"
                    name="postalCode"
                    className="form-input2"
                    placeholder="Postal Code"
                    value={userDetails.postalCode}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    name="address"
                    className="form-input"
                    placeholder="Address"
                    value={userDetails.address}
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="form-group tradingview-group">
                  <input
                    type="text"
                    name="tradingViewId"
                    className="form-input"
                    placeholder="TradingView ID"
                    value={userDetails.tradingViewId}
                    onChange={handleChange}
                    required
                  />
                  <button
                    type="button"
                    className="help-link"
                    onClick={() => setShowVideoModal(true)}
                  >
                    How to get TradingView ID?
                  </button>
                </div>

                <h3>Select a Plan</h3>
                <div className="plan-selection">
                  {plans.map((plan) => (
                    <button
                      key={plan.id}
                      className={`plan-btn ${selectedPlan?.id === plan.id ? 'active' : ''}`}
                      type="button"
                      onClick={() => setSelectedPlan(plan)}
                    >
                      {plan.name} - ₹{plan.price}
                    </button>
                  ))}
                </div>

                <div className="form-group terms-conditions">
                  <input
                    type="checkbox"
                    id="terms"
                    checked={termsAccepted}
                    onChange={() => setTermsAccepted(!termsAccepted)}
                  />
                  <label htmlFor="terms">
                    I accept the <a href="/terms" target="_blank">Terms and Conditions</a> and{' '}
                    <a href="/privacy" target="_blank">Privacy Policy</a>.
                  </label>
                </div>

                <button type="submit" className="form-submit-btn">Proceed to Pay</button>
              </div>
            </form>

            <div className="order-summary">
              <div className="badge">{selectedPlan?.name.split(' ')[0]}</div>
              <h3>Order Summary</h3>

              <table className="summary-table">
                <tbody>
                  <tr>
                    <td><strong>Plan</strong></td>
                    <td>{selectedPlan?.name}</td>
                  </tr>
                  <tr>
                    <td><strong>Description</strong></td>
                    <td>{selectedPlan?.description}</td>
                  </tr>
                  <tr>
                    <td><strong>Price</strong></td>
                    <td>₹{selectedPlan?.price}</td>
                  </tr>
                </tbody>
              </table>

              <div className="total-section">
                <h2>Total: ₹{selectedPlan?.price}</h2>
                <p>Secure Payment</p>
              </div>
            </div>
          </div>
        )}
      </div>

      {showVideoModal && <VideoModal />}

      <footer className="footer">
        <p>
          &copy; {new Date().getFullYear()} KredAllino Technologies. All rights reserved.
          <br />
          <a href="https://kredallino.com/terms-of-service/" target="_blank" rel="noopener noreferrer">
            Terms and Conditions
          </a>{' '}
          |{' '}
          <a href="https://kredallino.com/privacy-policy/" target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>
        </p>
      </footer>
    </div>
  );
};

export default PaymentPage;