import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import './SuccessPage.css';

const SuccessPage = () => {
  const location = useLocation(); // Access state passed from the payment page
  const navigate = useNavigate();
  const API_URL = process.env.REACT_APP_API_URL || 'api';
  const { plan, userDetails } = location.state || {};
  const queryParams = new URLSearchParams(location.search);
  const transactionId = queryParams.get('transactionId');
  console.log('Transaction ID:', transactionId);

  const [videoUrl, setVideoUrl] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchVideoUrl = async () => {
      try {
        const response = await axios.get(`${API_URL}/algomaxx/plans?for=ultra-signal`);
        console.log('Video URL response:', response.data);
        const videoLink = response.data[0]?.SuccessYoutubeLink;
        console.log('Video URL:', videoLink);
        setVideoUrl(videoLink);
      } catch (error) {
        console.error('Error fetching video URL:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchVideoUrl();
  }, []);

  return (
    <div className="success-page">
      <div className="container">
        <h2 className="success-header">Payment Successful!</h2>
        <p className="success-message">🎉 Congratulations! Your payment for your plan has been successfully processed.</p>
        <p className="email-message">Please check your email for further details.</p>

        {/* YouTube Video Tutorial */}
        <div className="tutorial-section">
          <h3>Next Steps</h3>
          <p>Watch the tutorial below to learn how to set up your account and start using the service:</p>

          <div className="video-container">
            {loading ? (
              <div className="loading">Loading video...</div>
            ) : (
              videoUrl ? (
                <iframe
                  width="560"
                  height="315"
                  src={videoUrl}
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              ) : (
                <p>Sorry, video not available.</p>
              )
            )}
          </div>
        </div>

        <div className="button-container">
          <button className="dashboard-button" onClick={() => window.location.href = 'https://in.tradingview.com'}>
            Go to TradingView
          </button>
          <button className="dashboard-button" onClick={() => window.location.href = 'https://docs.algomaxx.com'}>
            View Documentation
          </button>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;
