import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import './FailedPaymentPage.css'; // Reusing the same CSS from SuccessPage

const FailedPaymentPage = () => {
    const location = useLocation(); // Access state passed from the payment page
    const navigate = useNavigate();

    return (
        <div className="success-page">
            <h2>Access Failed</h2>
            <p>💔 Unfortunately, we were unable to provide you access to AlgoMaxx Pro due to an issue with your payment.</p>
            <p>Our support team is here to help you out!</p>
            <p>Please reach out to us:</p>

            <p>
                <strong>Customer Support Number:</strong> <a href="tel:+918277996021">+91 8277996021</a>
            </p>
            <p>
                <strong>Email:</strong> <a href="mailto:support@algomaxx.com">support@algomaxx.com</a>
            </p>

            <button
                className="dashboard-button"
                onClick={() => navigate('/')}
            >
                Retry Payment
            </button>
        </div>
    );
};

export default FailedPaymentPage;
